import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import { MainFiltersItem } from '../interfaces/clientData';

interface MainFiltersState {
    mainFilters: MainFiltersItem[] 
}

const initialState: MainFiltersState = {
    mainFilters: []
}

export const filtersSlice = createSlice({
    name: 'mainFilters',
    initialState,
    reducers:{
         setMainFilters: (state, action: PayloadAction<MainFiltersItem[]>) => {
             state.mainFilters = action.payload
         },
    }
})

const {actions, reducer} = filtersSlice;

export default reducer;
export {actions};
export const {
    setMainFilters,
} = actions;
//it is all static filters