import { CloseButton } from "../buttons/CloseButton";
import type { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { ProductWithQuantity } from "../../interfaces/clientData";
import { useEffect, useState } from "react";
import { cartListDelete } from "../../store/shoppingCart.slice";
import { useDispatchedActions } from "../../hooks/UseActions";
import localStorageFunction from "../../servises/localStorageFunc";
import { BookingItem } from "./BookingItem";
import { usePostDataMutation } from "../../servises/postServise";
// import { FormikErrors, FormikValues, useFormik } from "formik";
import { Popup } from "../../AdminPanel/components/Popup";
import { UserForm } from "./UserForm";

interface FavoritesProps {
  isShow: boolean;
  toggleShow: () => void;
}

interface OrderItem {
  productId: number;
  quantity: number;
}

interface Order {
  id: number;
  userId: number;
  userName: string;
  price: number;
  shippingCity: string;
  shippingAddress: string;
  orderItems: OrderItem[];
}

export const Booking: React.FC<FavoritesProps> = ({ isShow, toggleShow }) => {
  let localStorageFunc = localStorageFunction();
  let userData = localStorageFunc.getUserFromLocalStorage();

  if (!userData) {
    userData = {
      id: "",
      name: "",
      city: "",
      phoneNumber: "",
      adress: "",
    };
  }
  const [user, setUser] = useState(userData);
  const [isShowPopap, setIsShowPopap] = useState(false);
  const [text, setText] = useState("");
  const [total, setTotal] = useState(0);
  const cartDataWithQuantity = useSelector(
    (state: RootState) => state.shopingCartReducer.shoppingCartData
  );

  const deleteInStore = useDispatchedActions(cartListDelete);
  const [sendOrder, { isError, isLoading, isSuccess }] = usePostDataMutation();

  const changeUser = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    const key = (event.target as HTMLInputElement).name;
    setUser({ ...user, [key]: value });
  };

  const showPopapMessage = (isValue: boolean) => {
    setIsShowPopap(isValue);
  };

  /////////////// if we want get product with renewed price
  // let cartListId: string[] = [];
  // let urlCartList = "/products/byProductIds?";
  // let cartFromStorage: QuantityWithId;
  // let localStorageFunc = localStorageFunction();
  // cartFromStorage = localStorageFunc.getCartFromLocalStorage();

  // if (cartFromStorage) {
  //   cartListId = Object.keys(cartFromStorage);
  //   for (let id of cartListId) {
  //     urlCartList = urlCartList.concat(`productIds=${id}&`);
  //   }
  // }
  // const { data: cartData = [] } = postMethods().useGetDataQuery({
  //   url: urlCartList,
  //   lang: mainLanguage,
  // });

  // let cartDataWithQuantity: ProductWithQuantity[];
  // cartDataWithQuantity = cartData.map((cartItem: Product) => {
  //   return { ...cartItem, quantity: cartFromStorage[cartItem.id] };
  // });
  /////////////////////////////////

  let summ = cartDataWithQuantity.reduce(
    (amount: number, product: ProductWithQuantity) => {
      return (
        +amount.toFixed(2) +
        +(
          product.quantity *
          (product.price - product.price * (product.discount / 100))
        ).toFixed(2)
      );
    },
    0
  );

  useEffect(() => {
    setTotal(+summ.toFixed(2));
  }, [summ]);

  const deleteCart = () => {
    deleteInStore();
    localStorageFunc.deleteCartInLocalStorage();
  };

  const goodsList = cartDataWithQuantity.map((product) => {
    return { productId: product.id, quantity: product.quantity };
  });

  const order: Order = {
    id: 1,
    userId: 1,
    userName: user.name,
    price: summ,
    shippingCity: user.city,
    shippingAddress: user.adress,
    orderItems: goodsList,
  };

  const sentShoppingData = () => {
    if (cartDataWithQuantity.length) {
      sendOrder({ url: "/orders", body: order });
    }
  };

  // const { touched, errors, resetForm } = formik;
  useEffect(() => {
    if (isError) {
      setText("сталася помилка з`єднання з сервером");
      showPopapMessage(true);
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      setText("чекайте, ми з Вами зв'яжемося");
      showPopapMessage(true);
      deleteCart();
    }
  }, [isSuccess]);

  return (
    <div className="booking fixed flex flex-col justify-between bg-whitesmoke p-6 text-lg h-full max-h-full overflow-y-auto w-full">
      {isShowPopap && (
        <Popup
          title={""}
          text={text}
          className={"bg-secondary w-64"}
          onClose={() => showPopapMessage(false)}
        />
      )}
      <div
        className="booking-header flex items-center justify-between pb-4 h-20 border-b-4 
                      border-solid border-main_02"
      >
        <div className="font-bold text-3xl pl-4 ">Оформлення замовлення</div>
        <div className="font-bold text-3xl pl-4 w-1/2">
          <CloseButton
            clickFunction={toggleShow}
            className="w-full rounded-full ml-auto mr-0"
          />
        </div>
      </div>
      <UserForm user={user} changeUser={changeUser} />
      <div className="cart-list ">
        {cartDataWithQuantity.map((product: ProductWithQuantity) => {
          return (
            <div key={product.id} className="cursor-text">
              <BookingItem product={product}></BookingItem>
            </div>
          );
        })}
      </div>
      <div
        className="booking-footer flex flex-col p-4
                          border-solid border-1 border-secondary rounded-md bg-secondary_light"
      >
        <div className="flex justify-between ">
          <div>разом</div>
          <div>{total} грн</div>
        </div>
        <button
          onClick={() => {
            sentShoppingData();
          }}
          className="bg-secondary self-center w-64 h-16 rounded-md"
        >
          Замовлення підтверджую
        </button>
      </div>
    </div>
  );
};
