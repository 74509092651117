import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "./axiosBaseQuery";
import { apiUrl } from "../constants";

export const postAPI = createApi({
    reducerPath: "postAPI",
    tagTypes: ['Products', 'Categories',],
  
    baseQuery: axiosBaseQuery({
      baseUrl: apiUrl,
      // transformResponse: (response) => response
    }),
    endpoints: (builder) => ({
      getProducts: builder.query({
        query: ({ url, lang }) => ({
          url,
          headers: {
            "Api-Language": lang,
          },
        }),
        providesTags: ['Products'],
      }),
      getData: builder.query({
        query: ({ url, lang }) => ({
          url,
          headers: {
            "Api-Language": lang,
          },
        }),
      }),
      getCategories: builder.query({
        query: ({ url, lang }) => ({
          url,
          headers: {
            "Api-Language": lang,
          },
        }),
        providesTags: ['Categories'],
      }),
      postData: builder.mutation({
        query: ({ url, body }) => ({
          data: body,
          url: url,
          method: "POST",
        }),
      }),
      postProducts: builder.mutation({
        query: ({ url, body }) => ({
          data: body,
          url: url,
          method: "POST",
        }),
        invalidatesTags: ['Products'],
      }),
      postCategories: builder.mutation({
        query: ({ url, body }) => ({
          data: body,
          url: url,
          method: "POST",
        }),
        invalidatesTags: ['Categories'],
      }),
      putProducts: builder.mutation({
        query: ({ url, body }) => ({
          data: body,
          url: url + "/" + body.id,
          method: "PUT",
        }),
        invalidatesTags: ['Products'],
      }),
      putCategories: builder.mutation({
        query: ({ url, body }) => ({
          data: body,
          url: url + "/" + body.id,
          method: "PUT",
        }),
        invalidatesTags: ['Categories'],
      }),
      deleteProducts: builder.mutation({
        query: ({ url, id }) => ({
          url: url + "/" + id,
          method: "DELETE",
        }),
        invalidatesTags: ['Products'],
      }),
      deleteCategories: builder.mutation({
        query: ({ url, id }) => ({
          url: url + "/" + id,
          method: "DELETE",
        }),
        invalidatesTags: ['Categories'],
      }),
    }),
  });
  export const {
    useGetDataQuery,
    usePostDataMutation,
    useGetProductsQuery, 
    usePostProductsMutation,
    usePutProductsMutation,
    useDeleteProductsMutation,
    useGetCategoriesQuery,
    usePostCategoriesMutation,
    usePutCategoriesMutation,
    useDeleteCategoriesMutation,
    endpoints,
  } = postAPI;
  