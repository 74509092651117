import { Category } from "../../interfaces/clientData";
import { CatalogItem } from "./CatalogItem";
import { useGetCategoriesQuery } from "../../servises/postServise";
import { useSelector } from "react-redux";
import type { RootState } from "../../store/store";
import { CloseButton } from "../buttons/CloseButton";

export interface CatalogProps {
  isCatalogOpen: boolean;
  showCatalog: Function;
}

export const Catalog: React.FC<CatalogProps> = ({
  isCatalogOpen,
  showCatalog,
}) => {
  const mainLanguage = useSelector(
    (state: RootState) => state.languageReducer.languageValue
  );
  
  const {
    data: categories = [],
    isLoading,
    error,
  } = useGetCategoriesQuery({ url: "/categories", lang: mainLanguage });

  if (isLoading) return <div className="p-10 text-xs">...LOADING</div>;

  if (error) return <h1 className="p-10">ERROR</h1>;
  const showMobileCatalog=()=>{
    showCatalog(!isCatalogOpen)
  }
  return (
    <nav
      className={`catalogNav-wrap absolute  bg-[white]
                        text-base w-full h-full top-0 z-30
                        ${isCatalogOpen ? "block" : "hidden"} 
                        lg:block lg:relative lg:left-0 lg:top-0 
                        lg:bg-main lg:text-modal lg:z-10`}
    >
      <CloseButton
        clickFunction={showMobileCatalog}
        className="w-full rounded-full ml-auto mr-0
                   lg:hidden "
      />
      <div
        className="catalogList flex flex-col
                  lg:flex-row lg:justify-between
                            "
      >
        {categories ? (
          categories.map((category: Category) => (
            <CatalogItem key={category.id} category={category} showMobileCatalog={showMobileCatalog} />
          ))
        ) : (
          <div className="text-center font-bold"> данных пока нет</div>
        )}
      </div>
    </nav>
  );
};
