import { useEffect, useState } from "react";
import { Popup, PopupProps } from "../components/Popup";
import { CreateAttributeModal } from "./components/CreateAttributeModal";
import { AttributeItem } from "./components/AttributeItem";
import { Product, ProductAttribute } from "../../interfaces/admin.data";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../../constants";


export const AttributesList = () => {
    const [product, setProduct] = useState<Product>({} as Product)
    const [attributes, setAttributes] = useState<ProductAttribute[]>([]);
    const [createAttributeModalIsOpen, setCreateAttributeModalIsOpen] = useState<boolean>(false);
    const [popupIsOpen, setPopupIsOpen] = useState(false);
    const [popup, setPopup] = useState<PopupProps>({onClose: () => setPopupIsOpen(false)});
    const {id} = useParams<string>();

    useEffect(() => {
      const fetchData = async () => {
        setPopupIsOpen(false);
          try {
              const {data} = await axios.get(`${apiUrl}/productsAdmin/${id}`);
              setProduct(data);
              setAttributes(data.productAttributes);
          } catch (error) {
              setPopup({ ...popup, title: "Ошибка!", text: "Не удалось загрузить данные продукта", className: "bg-danger_light"});
          } finally{
            setPopupIsOpen(true);
          }
      };
  
      fetchData();        
  }, [id]);
    
    const createAttribute = async (attribute: ProductAttribute) => {
      attribute = id? {...attribute, productId: +id} : attribute;
      setPopupIsOpen(false);
      try {
          const response = await axios.post(apiUrl + `/productAttributesAdmin?productId=${id}`, attribute)
          setAttributes(prev => [...prev, response.data])
          setPopup({ ...popup, title: "Выполнено!", text: "Новый атрибут добавлен к продукту", className: "bg-success_light"});
      } catch (error) {
          setPopup({ ...popup, title: "Ошибка!", text: "Не удалось добавить новый атрибут к продукту", className: "bg-danger_light"});
      } finally{
        setPopupIsOpen(true);
      }
  }
    const removeAttribute = async (attributeId: number) => {
      setPopupIsOpen(true);
      try {
        await axios.delete(apiUrl + `/productAttributesAdmin/${attributeId}`)
        setAttributes(attributes.filter(prev => prev.attributeId !== attributeId));
        setPopup({ ...popup, title: "Выполнено!", text: "Атрибут удален", className: "bg-success_light"});
    } catch (error) {
        setPopup({ ...popup, title: "Ошибка!", text: "Не удалось удалить атрибут продукта", className: "bg-danger_light"});
    } finally{
      setPopupIsOpen(true);
    }
    }

    return(
    <>
      <h1 className="font-bold my-4">Атрибуты продукта с Id: {id}</h1>
      <div className="flex justify-between my-3">
        <button
          className='px-4 py-2 bg-succes rounded-md font-bold text-modal'
          onClick={() => setCreateAttributeModalIsOpen(true)}
        >
          Добавить атрибут
        </button>
        <Link
          to={"/admin/products"}
          className='px-4 py-2 inline-block h-9 bg-primary rounded-md font-bold text-modal'
        >
          Назад
        </Link>
      </div>

      {popupIsOpen &&
        <Popup title={popup.title} text={popup.text} onClose={popup.onClose} className={popup.className}/>
      }

      {createAttributeModalIsOpen&&
        <CreateAttributeModal
        createAttribut={createAttribute}
        isOpen={createAttributeModalIsOpen}
        onClose={() => setCreateAttributeModalIsOpen(false)}
        />
      }
      <table className='table-auto w-full border-spacing-2 mb-5'>
          <thead>
            <tr>
              <th><b>Id</b></th>
              <th><b>Id продукта</b></th>
              <th><b>Имя на RU</b></th>
              <th><b>Значение на RU</b></th>
              <th><b>Имя на UA</b></th>
              <th><b>Значение на UA</b></th>
            </tr>
          </thead>
          <tbody>
            {attributes.length ? (
              attributes.map((attribute: ProductAttribute) => (
                <AttributeItem
                  key={attribute.attributeId}
                  attribute={attribute}
                  removeAttribute={removeAttribute}
                />
              ))
            ) : (
              <tr>
                <td className='text-center font-bold' colSpan={6}>У продукта пока нет аттрибутов</td>
              </tr>
            )}
          </tbody>
      </table>
    </>
    );
}