import { ProductWithQuantity } from "../../interfaces/clientData";
import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  cartItemQuantityChange,
  cartListDeleteItem,
} from "../../store/shoppingCart.slice";
import { useDispatchedActions } from "../../hooks/UseActions";
import localStorageFunction from "../../servises/localStorageFunc";

interface CartItemProps {
  product: ProductWithQuantity;
  closeCart: () => void;
}

const deleteButton = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m6 4.125 2.25 2.25m0 0 2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
      />
    </svg>
  );
};

export const ShoppingCartItem: React.FC<CartItemProps> = ({
  product,
  closeCart,//if we click and open product card we must close shoppingCart
}) => {
  const link = `/products/${product.id}`;
  const [count, setCount] = useState(product.quantity);
  const changeQuantityInStore = useDispatchedActions(cartItemQuantityChange);
  const deleteItemInStore = useDispatchedActions(cartListDeleteItem);
  let localStorageFunc = localStorageFunction();

  let total = +(
    product.quantity *
    (product.price - product.price * (product.discount / 100))
  ).toFixed(2);
  
  const handleMinus = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  }; 
  
  const handlePlus = () => {
    setCount(count + 1);
  };
  useEffect(()=>{
    setCount(product.quantity)
  },[product.quantity])

  useEffect(() => {
    changeQuantityInStore({ [product.id]: count });
    localStorageFunc.changeCartItemQuantityInLocalStorage(product.id.toString(), count);
  }, [count]);

  const deleteItem=()=>{
    deleteItemInStore(product.id);
    localStorageFunc.deleteCartItemFromLocalStorage(product.id);
  }
 
  return (
    <div className="cartItem flex py-4">
      <NavLink
        to={link}
        onClick={closeCart}
        className="cartItem-img relative contentItem-img h-[80px] w-[80px]"
      >
        <img
          className="object-scale-down h-full w-full"
          src={product.imgPath}
          alt="foto"
        />
        {!!product.discount && (
          <div
            className="catrItem-action absolute w-12 h-5 p-[1px] rounded-[8px] text-center 
                      text-sm left-0 top-0 bg-[red] text-[white] transform 
                      skew-y-[-12deg] skew-x-12
                "
          >
            {product.discount}%
          </div>
        )}
      </NavLink>
      <div className="flex flex-col w-full mx-4 ">
        <div className="flex justify-between">
          <NavLink to={link} onClick={closeCart} className="self-start">
            {product.name}{" "}
          </NavLink>
          <button onClick={deleteItem}>
            {deleteButton()}
          </button>
        </div>
        <div className="cartItem-price flex flex-row items-center justify-between pl-[30%]">
          {product.discount ? (
            <div>
              <div className="text-[16px] line-through ">
                {product.price} грн
              </div>
              <div className="text-[20px]  text-red">
                {product.price - product.price * (product.discount / 100)} грн
              </div>
            </div>
          ) : (
            <div className="text-[20px] ">{product.price} грн</div>
          )}
          <div className="flex items-center">
            <button
              className="cartItem-button h-10 w-10 border-main_02 rounded-[100%] pb-2 border-1 
                          "
              onClick={handleMinus}
            >
              -
            </button>
            <input
              type="number"
              min={1}
              value={count}
              onChange={(e) => setCount(+(e.target.value))}
              className="text-[14px] border-1 border-main_02 w-[40px] 
              h-[40px] m-2 text-center pl-2 "
            />
            <button
              className="cartItem-button h-10 w-10 border-1 border-main_02 rounded-[100%]"
              onClick={handlePlus}
            >
              +
            </button>
          </div>
          <div>{total}грн</div>
        </div>
      </div>
    </div>
  );
};