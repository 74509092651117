import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface SearchPanelState {
    searchPanelValue: string;
  }

const initialState: SearchPanelState = {
  searchPanelValue: ''
};

export const searchPanelValueSlice = createSlice({
  name: "searchPanelValue",
  initialState,
  reducers: {
    setSearchPanelValue: (state, action: PayloadAction<string>) => {
      state.searchPanelValue = action.payload
    },
  },
});

const { actions, reducer } = searchPanelValueSlice;

export default reducer;
export { actions };
export const { setSearchPanelValue } = actions;