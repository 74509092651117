import { ProductWithQuantity } from "../../interfaces/clientData";

interface BookingItemProps {
  product: ProductWithQuantity;
}

export const BookingItem: React.FC<BookingItemProps> = ({
  product,
}) => {
  const link = `/products/${product.id}`;

  let total = +(
    product.quantity *
    (product.price - product.price * (product.discount / 100))
  ).toFixed(2);
  
 
  return (
    <div className="bookingItem flex py-4">
      <div
        onClick={()=> window.open(link,'_blank', 'noopener,noreferrer')}
        className="bookingItem-img relative cursor-pointer h-[80px] w-[80px]"
      >
        <img
          className="object-scale-down h-full w-full"
          src={product.imgPath}
          alt="foto"
        />
        {!!product.discount && (
          <div
            className="catrItem-action absolute w-12 h-5 p-[1px] rounded-[8px] text-center 
                      text-sm left-0 top-0 bg-[red] text-[white] transform 
                      skew-y-[-12deg] skew-x-12">
            {product.discount}%
          </div>
        )}
      </div>
      <div className="flex flex-col w-full mx-4 ">
        <div className="flex justify-between">
          <div  onClick={()=> window.open(link,'_blank', 'noopener,noreferrer')} className="self-start cursor-pointer">
            {product.name}
          </div>
        </div>
        <div className="bookingItem-price flex flex-row items-center justify-between pl-[30%]">
          {product.discount ? (
            <div>
              <div className="text-[16px] line-through ">
                {product.price} грн
              </div>
              <div className="text-[20px]  text-red">
                {product.price - product.price * (product.discount / 100)} грн
              </div>
            </div>
          ) : (
            <div className="text-[20px] ">{product.price} грн</div>
          )}
          <div className="flex items-center">
            
            <div
              className="text-[14px] "
            />{product.quantity}
            
          </div>
          <div>{total}грн</div>
        </div>
      </div>
    </div>
  );
};