import { Languages } from "../../components/languagePanel/LanguagePanel";
import { useGetDataQuery } from "../../servises/postServise";
import { useSelector } from "react-redux";
import type { RootState } from "../../store/store";

export interface RequiredQuatnityProps {
  locationPathName: string;
  locationSearch: string;
};

export const RequiredQuatnity:React.FC<RequiredQuatnityProps> = ( {locationPathName, locationSearch}) => {

  const mainLanguage = useSelector(
    (state: RootState) => state.languageReducer.languageValue
  );

  const {
    data: number,
    isLoading,
    error,
  } = useGetDataQuery({ url: locationPathName + "/count" + locationSearch });

  if (isLoading) return <div>...LOADING</div>;
  if (error) return <div>ERROR</div>;
  return (
    <div>
      {Languages.Ukrainian === mainLanguage ? "знайдено:" : "найдено:"}
      {number}
    </div>
  );
};
