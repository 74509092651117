import type { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { BreadCrumbItem } from "../../store/breadCrumb.slice";
import { NavLink } from "react-router-dom";

export const BreadCrumb = () => {
  const breadcrumbData = useSelector(
    (state: RootState) => state.breadCrumbReducer.dataForBreadCrumb
  );
  const showBreadCrumbItem = (breadCrumbArr: Array<BreadCrumbItem>) => {
    return breadCrumbArr.map((el: BreadCrumbItem) => {
      const { hierarchy ,name, link } = el;
      return (
        <li key={hierarchy} className="breadcrumb-item before:content-['>'] before:mx-2 before:text-secondary before:font-bold">
          <NavLink
            to={link}
          >
            {name}
          </NavLink>
        </li>
      );
    });
  };

  return (
    <nav>
      <ol className="breadcrumb flex bg-main_02">
        <li className="breadcrumb-item flex">
          <a href="/">
            <img
              className="logo-img inline-block h-5 w-5 mr-2"
              src="ELGOODS-64.png"
              alt="logo"
            />
          </a>
        </li>
           {showBreadCrumbItem(breadcrumbData)}
      </ol>
    </nav>
  );
};
