import {Category} from '../../interfaces/clientData';
import { useShowFilterContext } from '../../servises/showComponentContext';
import { NavLink } from 'react-router-dom';

interface CatalogItemProps {
    category:Category
    showMobileCatalog: Function;
  }

export const CatalogItem : React.FC<CatalogItemProps> = ({category, showMobileCatalog}) => {

    const {showFilter} = useShowFilterContext();
    const link = `/products?categoryIds=${category.id}`;

    return(
        <NavLink to={link}
            onClick={()=>{
                showFilter(true);
                showMobileCatalog(); 
        }}
            className="catalogItem py-2 px-4 bg-modal mt-1 mr-1 cursor-pointer
            hover:bg-secondary hover:text-main
            transition-shadow duration-300 rounded-lg
            lg:bg-opacity-0 lg:mt-0 lg:rounded-none lg:mr-0
            ">
            <div className='px-2'>{category.name}</div>
        </NavLink>
    );
}