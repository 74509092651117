import { SelectHTMLAttributes } from "react";

export interface SelectOption {
    value: string | number;
    label: string;
}

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
    options: SelectOption[];
}

export const Select: React.FC<SelectProps> = ({ options, ...props}) => {
    return(
        <select {...props}>
            {options &&
                options.map(opt => (
                    <option
                        key={opt.label}
                        value={opt.value}
                    >
                        {opt.label}
                    </option>
                ))
            }
        </select>
    )
}