import { Logo } from "../logo/Logo";
import { SearchPanel } from "../searchPanel/SearchPanel";
import { ContactsPanel } from "../contactsPanel/ContactsPanel";
import { IconsPanel } from "../iconsPanel/IconsPanel";
import { LanguagePanel } from "../languagePanel/LanguagePanel";
import { CatalogPanelButton } from "../catalogPanel/CatalogPanelBatton";
import { Catalog } from "../navigation/CatalogList";
import { useEffect, useState } from "react";
import type { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { addAllFavorites } from "../../store/favorites.slice";
import { useGetProductsQuery} from "../../servises/postServise";
import { useDispatchedActions } from "../../hooks/UseActions";
import{ cartSet,} from "../../store/shoppingCart.slice"
import { ProductWithQuantity, Product } from "../../interfaces/clientData";
import localStorageFunction from "../../servises/localStorageFunc";

interface QuantityWithId {
  [id: number]: number,
}

export const Header = () => {
  const [isCatalogOpen, setIsCatalogOpen] = useState(false);
  function showCatalog(value: boolean) {
    setIsCatalogOpen(value);
  };
  let localStorageFunc = localStorageFunction();
 
  const mainLanguage = useSelector(
    (state: RootState) => state.languageReducer.languageValue
  );
  let favoritesId: string[] = [];
  let url = "/products/byProductIds?";

  favoritesId = localStorageFunc.getFavoritesFromLocalStorage();
  if (favoritesId) {
    for (let id of favoritesId) {
      url = url.concat(`productIds=${id}&`);
    }
  };

  const {
    data: favorites = [],
  } = useGetProductsQuery({
    url: url,
    lang: mainLanguage,
  });
  
  const addToFavorites = useDispatchedActions(addAllFavorites);
  useEffect(() => {
    addToFavorites(favorites);
  }, [favorites]);
 
  let cartListId: string[] = [];
  let urlCartList = "/products/byProductIds?";
  let cartListObj:QuantityWithId;

  cartListObj = localStorageFunc.getCartFromLocalStorage();

  if (cartListObj) {
    cartListId = Object.keys(cartListObj);
    for (let id of cartListId) {
      urlCartList = urlCartList.concat(`productIds=${id}&`);
    }
  }; 

  const {
    data: cartData = [],
  } = useGetProductsQuery({
    url: urlCartList,
    lang: mainLanguage,
  });

  let cartDataWithQuantity:ProductWithQuantity[]; 
  cartDataWithQuantity = cartData.map((cartItem:Product)=>{
    return({...cartItem,'quantity': cartListObj[cartItem.id]})
  })

  const addToCart = useDispatchedActions(cartSet);
  useEffect(() => {
    addToCart(cartDataWithQuantity);
  }, [cartData]);

  return (
    <header
      className="grid grid-rows-2 grid-cols-12 gap-y-4 
                           xs:grid-cols-10 
                           lg:flex lg:flex-wrap lg:flex-row lg:justify-between lg:items-center"
    >
      <div className="col-span-2">
        <Logo />
      </div>
      <div
        className={`row-start-3 col-start-2 col-end-12
                            xs:row-start-2 xs:col-start-1 xs:col-end-4
                            lg:hidden`}
        onClick={() => {
          showCatalog(!isCatalogOpen);
        }}
      >
        <CatalogPanelButton />
      </div>
      <div
        className="row-start-2 col-start-2 col-end-12 col-span-8 
                            xs:col-start-5 xs:col-end-12 
                            lg:col-start-1 lg:col-span-12"
      >
        <SearchPanel />
      </div>
      <div className="row-start-1 col-start-5 col-span-4 xs:col-start-4">
        <ContactsPanel />
      </div>
      <div
        className="row-start-1 col-start-7 col-span-1 
                            xs:col-start-8 xs:col-span-1"
      >
        <IconsPanel />
      </div>
      <div
        className="row-start-1 col-start-12 col-span-1 justify-self-end 
                            xs:col-start-10 xs:col-span-1"
      >
        <LanguagePanel />
      </div>
      <div
        className="row-start-3 w-full
                            md:col-start-1 md:col-end-12"
      >
        <Catalog isCatalogOpen={isCatalogOpen} showCatalog={showCatalog} />
      </div>
    </header>
  );
};
