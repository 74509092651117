interface QuantityWithId {
  [id: string]: number;
}
export interface User {
  id: string;
  name: string;
  city: string;
  phoneNumber: string;
  adress: string;
}

type LocalStorageFunctions = {
  getFavoritesFromLocalStorage: () => string[];
  addFavoriteToLocalStorage: (productId: number) => void;
  deleteFavoriteItemFromLocalStorage: (productId: number) => void;
  deleteFavoritesInLocalStorage: () => void;
  getCartFromLocalStorage: () => QuantityWithId;
  addCartItemToLocalStorage: (key: string, value: number) => void;
  deleteCartItemFromLocalStorage: (productId: number) => void;
  deleteCartInLocalStorage: () => void;
  changeCartItemQuantityInLocalStorage: (key: string, value: number) => void;
  getUserFromLocalStorage: () => User;
  addUserToLocalStorage: (value: User) => void;
};

export default function localStorageFunction(): LocalStorageFunctions {
  let localStorage = window.localStorage;
  let wishList: string | null;
  let favoritesInLocalStorage: string[] = [];

  let cart: string | null;
  let cartInLocalStorage: QuantityWithId = {};

  let user: string | null;
  let userInLocalStorage: User;
  ///favorites
  const getFavoritesFromLocalStorage = () => {
    wishList = localStorage.getItem("wishList");
    if (wishList) {
      favoritesInLocalStorage = JSON.parse(wishList);
    }
    return favoritesInLocalStorage;
  };

  const setFavoritesToLokalStorage = (value: Object) => {
    wishList = JSON.stringify(value);
    localStorage.setItem("wishList", wishList);
  };

  const deleteFavoriteItemFromLocalStorage = (productId: number) => {
    favoritesInLocalStorage = getFavoritesFromLocalStorage();
    favoritesInLocalStorage = favoritesInLocalStorage.filter((id) => {
      return +id !== productId;
    });
    setFavoritesToLokalStorage(favoritesInLocalStorage);
  };

  const addFavoriteToLocalStorage = (productId: number) => {
    favoritesInLocalStorage = getFavoritesFromLocalStorage();
    favoritesInLocalStorage.push(productId.toString());
    setFavoritesToLokalStorage(favoritesInLocalStorage);
  };

  const deleteFavoritesInLocalStorage = () => {
    getFavoritesFromLocalStorage();
    favoritesInLocalStorage = [];
    wishList = JSON.stringify(favoritesInLocalStorage);
    setFavoritesToLokalStorage(favoritesInLocalStorage);
  };

  ///shoppingCart
  const getCartFromLocalStorage = () => {
    cart = localStorage.getItem("cartList");
    if (cart) {
      cartInLocalStorage = JSON.parse(cart);
    }
    return cartInLocalStorage;
  };

  const setCartToLocalStorage = (value: Object) => {
    cart = JSON.stringify(value);
    localStorage.setItem("cartList", cart);
  };

  const deleteCartItemFromLocalStorage = (productId: number) => {
    cartInLocalStorage = getCartFromLocalStorage();
    delete cartInLocalStorage[productId.toString()];
    setCartToLocalStorage(cartInLocalStorage);
  };

  const addCartItemToLocalStorage = (key: string, value: number) => {
    cartInLocalStorage = getCartFromLocalStorage();
    cartInLocalStorage[key] = value;
    setCartToLocalStorage(cartInLocalStorage);
  };

  const deleteCartInLocalStorage = () => {
    getCartFromLocalStorage();
    cartInLocalStorage = {};
    setCartToLocalStorage(cartInLocalStorage);
  };

  const changeCartItemQuantityInLocalStorage = (key: string, value: number) => {
    getCartFromLocalStorage();
    cartInLocalStorage[key] = value;
    setCartToLocalStorage(cartInLocalStorage);
  };

  ///userData
  const getUserFromLocalStorage = () => {
    user = localStorage.getItem("user");
    if (user) {
      userInLocalStorage = JSON.parse(user);
    }
    return userInLocalStorage;
  };

  const addUserToLocalStorage = (value: User) => {
    user = JSON.stringify(value);
    localStorage.setItem("user", user);
  };

  return {
    getFavoritesFromLocalStorage,
    addFavoriteToLocalStorage,
    deleteFavoriteItemFromLocalStorage,
    deleteFavoritesInLocalStorage,

    getCartFromLocalStorage,
    addCartItemToLocalStorage,
    deleteCartInLocalStorage,
    deleteCartItemFromLocalStorage,
    changeCartItemQuantityInLocalStorage,

    getUserFromLocalStorage,
    addUserToLocalStorage,
  };
}
