import { useState, useEffect} from "react";
import { ShevronDownIcon } from "../../assets/icons/ShevronDown";
import { CheckboxElement } from "./CheckboxElement";

interface Element {
  id: number;
  name: string;
};

const filterItem = (arr: Element[], requestName: string) => {
  return (
    <div className="filtreCheckBox-wrap dropdown-content max-h-40 
                    overflow-y-auto scrollable-list py-2 bg-modal">
      {arr.map((element) => {
        return (
          <div key={element.id} >
            <CheckboxElement   
            name={element.name}
            requestName={requestName}
            id={element.id}
            />
          </div>
        );
      })}
    </div>
  );
};

export const FiltersElement: React.FC<{
  name: string;
  arr: Element[];
  requestName:string
}> = ({ name, arr, requestName}) => {

  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const toggleDropdown = (isOpen: boolean, setIsOpen: Function) => {
    setIsOpen(!isOpen);
  };

  const shevronDownIcon = ShevronDownIcon();

  return (
    <div>
      <label
        className="filterElement flex justify-between mt-5"
        onClick={() => toggleDropdown(isOpenFilter, setIsOpenFilter)}
        htmlFor={name}
      >
        {name} {shevronDownIcon}
      </label>
      {isOpenFilter && filterItem(arr, requestName)}
    </div>
  );
};