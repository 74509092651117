import { NavLink } from "react-router-dom";
import { Product} from "../../interfaces/clientData";
import type { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { useDispatchedActions } from "../../hooks/UseActions";
import { addFavorite, deleteFavoritesItem } from "../../store/favorites.slice";
import {
  cartListItemSet,
  cartListDeleteItem,
} from "../../store/shoppingCart.slice";
import localStorageFunction from "../../servises/localStorageFunc";

const isExist = (arr: Product[], id: number) => {
  for (let elem of arr) {
    if (elem.id === id) {
      return true;
    }
  }
  return false;
};

export const ContentItem: React.FC<Product> = (product) => {
  const link = `/products/${product.id}`;
  let localStorageFunc = localStorageFunction();

  const favorites = useSelector(
    (state: RootState) => state.favoritesReducer.favoritesData
  );
  const shopingCartData = useSelector(
    (state: RootState) => state.shopingCartReducer.shoppingCartData
  );

  const addToFavorites = useDispatchedActions(addFavorite);
  const deleteFromFavorites = useDispatchedActions(deleteFavoritesItem);
  const addCartListItem = useDispatchedActions(cartListItemSet);
  const deleteCartListItem = useDispatchedActions(cartListDeleteItem);

  let isExistInCartList: boolean = isExist(shopingCartData, product.id);
  let isExistInFavorites: boolean = isExist(favorites, product.id);

  const toggleFavorites = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (isExistInFavorites) {
      deleteFromFavorites(product.id);
      localStorageFunc.deleteFavoriteItemFromLocalStorage(product.id);
    } else {
      addToFavorites(product);
      localStorageFunc.addFavoriteToLocalStorage(product.id);
    }
  };

  const toggleShoppingCart = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (isExistInCartList) {
      deleteCartListItem(product.id);
      localStorageFunc.deleteCartItemFromLocalStorage(product.id);
    } else {
      addCartListItem({ ...product, quantity: 1 });
      localStorageFunc.addCartItemToLocalStorage(product.id.toString(), 1);
    }
  };

  return (
    <NavLink
      to={link}
      className="contentItem relative flex flex-col py-4 px-4 m-4 cursor-default
                w-full h-[400px] border-solid border-1 border-main_02 
                transition-all duration-200 rounded-2xl hover:border-4
                
                "
    >
      {!!product.discount && (
        <div
          className="action absolute w-12 h-5 p-[1px] rounded-[8px] text-center
                      bg-[red] text-[white] transform skew-y-[-12deg] skew-x-12
            "
        >
          {product.discount}%
        </div>
      )}
      <button
        onClick={(e) => {
          toggleFavorites(e);
        }}
        className="favorites px-[1px] md:px-1 cursor-default flex justify-end"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="-2 0 28 22"
          strokeWidth={1.5}
          className={`w-6 h-6 sm:w-8 sm:h-8 rounded-full cursor-pointer ${
            isExistInFavorites ? "fill-secondary stroke-main" : "fill-none"
          } 
                     stroke-secondary hover:stroke-main`}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
          />
        </svg>
      </button>
      <div className="contentItem-img h-1/2">
        <img
          className="object-scale-down h-full w-full"
          src={product.imgPath}
          alt="foto"
        />
      </div>

      <div className="pt-4 mx-auto">{product.name}</div>
      <div className="pt-4 h-1/5 mx-auto">{product.description}</div>
      <div className="flex justify-between items-center">
        {product.discount ? (
          <div>
            <div className="text-[16px] line-through ">{product.price} грн</div>
            <div className="text-[20px]  text-red">
              {product.price - product.price * (product.discount / 100)} грн
            </div>
          </div>
        ) : (
          <div className="text-[20px] ">{product.price} грн</div>
        )}
        <button
          onClick={(e) => {
            toggleShoppingCart(e);
          }}
          className="favorites px-[1px] md:px-1 cursor-default w-12 h-12 
                  shadow flex items-center justify-center
                  rounded-[10px]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="-2 0 28 22"
            strokeWidth={1.5}
            className={`w-6 h-6 sm:w-8 sm:h-8 rounded-full cursor-pointer ${
              isExistInCartList ? "fill-secondary stroke-main" : "fill-none"
            } 
                     stroke-secondary hover:stroke-main`}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
            />
          </svg>
        </button>
      </div>
    </NavLink>
  );
};
