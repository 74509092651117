import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface FiltersItem {
  [requestName: string]: number[];
}

interface FilterDataState {
  filterData: FiltersItem[];
}

const initialState: FilterDataState = {
  filterData: [],
};

export const filterDataChecked = createSlice({
  name: "filterData",
  initialState,
  reducers: {
    
    setFilterName: ( //just add arr of filters with empty array
                    // [{"categoryIds": []},{"countryIds": []},{"manufacturerIds": []}]
      state, action: PayloadAction<FiltersItem[]>) => {
      state.filterData = action.payload;
    },

    addFilterName: ( 
      state, action: PayloadAction<FiltersItem>) => {
      state.filterData.push(action.payload)
    },

    setFilterDefaultCheckBox: ( // add start value example [{"categoryIds": [47]},{"countryIds": []},{"manufacturerIds": []}]
    //don't push because when you switch between categories from the header (not checkboxes) the ID numbers will accumulate
      state,
      action: PayloadAction<{ [key: string]: number }>
    ) => {
      let payloadKey = Object.keys(action.payload)[0];
      let payloadValue = action.payload[payloadKey];
      let a = state.filterData.map((el) => { 
        if (Object.keys(el)[0] === payloadKey) {
          el[payloadKey] = [payloadValue];
        }
        return el;
      });
      state.filterData = a;
    },

    setFilterCheckBox: (// push id what you checked
      state,
      action: PayloadAction<{ [key: string]: number }>
    ) => {
      let key = Object.keys(action.payload)[0];
      let value = action.payload[key];
      let a = state.filterData.map((el) => { //el = categoryIds:[1,2,8]
        if (Object.keys(el)[0] === key) {
          el[key].push(value);
        }
        return el;
      });
      state.filterData = a;
    },

    deleteFilterCheckBox: (// delete id what you checked
      state,
      action: PayloadAction<{ [key: string]: number }>
    ) => {
      let key = Object.keys(action.payload)[0];
      let value = action.payload[key];
      let a = state.filterData.map((el) => { //look like el = categoryIds:[1,2,8]
        let newEl:number[]
        if (Object.keys(el)[0] === key) {
            newEl = el[key].filter((id) => { //look like el.categoryIds = [1,2,8]
            return id!==value
          })
          el[key]=newEl
        }
        return el;
      });
      state.filterData = a;
    },
  },
});
const { actions, reducer } = filterDataChecked;
export default reducer;
export { actions };
export const { setFilterCheckBox, deleteFilterCheckBox, setFilterDefaultCheckBox, setFilterName, addFilterName } = actions;
//store for only cheked filters