import { FormikErrors, FormikValues, useFormik } from "formik";
import { ModalWindow } from "../../components/ModalWindow";
import { Category, Country, Manufacturer, Product } from "../../../interfaces/admin.data";
import { apiUrl } from "../../../constants";
import axios from "axios";
import { useEffect, useState } from "react";
import { Select, SelectOption } from "../../components/Select";

interface CreateProductModalProps{
    createProduct: Function;
    isOpen: boolean;
    onClose: Function;
}


export const CreateProductModal:React.FC<CreateProductModalProps> = ({createProduct, isOpen, onClose}) => {
    const [categories, setCategories] = useState<Category[]>([])
    const [countries, setCountries] = useState<Country[]>([])
    const [manufacturers, setManufacturers] = useState<Manufacturer[]>([])
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [categoriesResponse, countriesResponse, manufacturersResponse] = await Promise.all([
                    axios.get(`${apiUrl}/categoriesAdmin`),
                    axios.get(`${apiUrl}/countriesAdmin`),
                    axios.get(`${apiUrl}/manufacturersAdmin`)
                ]);
                await setCategories(categoriesResponse.data);
                await setCountries(countriesResponse.data);
                await setManufacturers(manufacturersResponse.data);
                formic.setValues({
                    ...formic.values,
                    categoryId: categories.length? categories[0].id : 0,
                    countryId: countries.length? countries[0].id : 0,
                    manufacturerId: manufacturers.length? manufacturers[0].id : 0,
                })
            } catch (error) {
                console.error('Ошибка при загрузке данных:', error);
            }
        };
    
        fetchData();        
    }, []);
    const formic = useFormik({
        initialValues: {
            id: 0,
            name: '',
            nameUK: '',
            description: '',
            descriptionUK: '',
            imgPath: '',
            price: 0,
            stockQuantity: 0,
            discount: 0,
            isActive: false,
            categoryId: 0,
            countryId: 0,
            manufacturerId: 0,
            productAttributes: null
        } as Product,
        validate: values => {
            let errors: FormikErrors<FormikValues> = {};
            if(!values.name) errors.name = 'Поле не может быть пустым';
            if(!values.nameUK) errors.nameUK = 'Поле не может быть пустым';
            if(!values.description) errors.description = 'Поле не может быть пустым';
            if(!values.descriptionUK) errors.descriptionUK = 'Поле не может быть пустым';
            if(!values.imgPath) errors.imgPath = 'Поле не может быть пустым';
            if(!values.price || values.price < 0) errors.price = 'Поле не может быть пустым или отрецательным';
            if(!values.stockQuantity || values.stockQuantity < 0) errors.stockQuantity = 'Поле не может быть пустым или отрецательным';
            if(!values.discount || values.discount < 0) errors.discount = 'Поле не может быть пустым или отрецательным';
            return errors;
        },
        onSubmit: values => {
            values = {...values, categoryId: Number(values.categoryId), countryId: Number(values.countryId), manufacturerId: Number(values.manufacturerId)}
            createProduct(values as Product);
            resetForm();
        },
    })

    const {touched, errors, resetForm} = formic;

    return (
        <ModalWindow isOpen={isOpen} onClose={onClose}>
            <h3 className="text-center text-lg font-bold">Добавление нового продукта</h3>
            <form onSubmit={formic.handleSubmit} className="flex flex-col">
                <label className="mt-5" htmlFor="name">Имя на русском</label>
                <input
                    id="name"
                    name="name"
                    type="text"
                    className="rounded-md px-1"
                    onChange={formic.handleChange}
                    onBlur={formic.handleBlur}
                    value={formic.values.name}
                />
                {touched.name && errors.name ? (<div className="font-bold text-danger">{errors.name}</div>) : null}
                <label className="mt-5" htmlFor="name">Имя на украинском</label>
                <input
                    id="nameUK"
                    name="nameUK"
                    type="text"
                    className="rounded-md px-1"
                    onChange={formic.handleChange}
                    onBlur={formic.handleBlur}
                    value={formic.values.nameUK}
                />
                {touched.nameUK && errors.nameUK ? (<div className="font-bold text-danger">{errors.nameUK}</div>) : null}
                <label className="mt-5" htmlFor="description">Описание на русском</label>
                <input
                    id="description"
                    name="description"
                    type="text"
                    className="rounded-md px-1"
                    onChange={formic.handleChange}
                    onBlur={formic.handleBlur}
                    value={formic.values.description}
                />
                {touched.description && errors.description ? (<div className="font-bold text-danger">{errors.description}</div>) : null}
                <label className="mt-5" htmlFor="descriptionUK">Описание на украинском</label>
                <input
                    id="descriptionUK"
                    name="descriptionUK"
                    type="text"
                    className="rounded-md px-1"
                    onChange={formic.handleChange}
                    onBlur={formic.handleBlur}
                    value={formic.values.descriptionUK}
                />
                {touched.descriptionUK && errors.descriptionUK ? (<div className="font-bold text-danger">{errors.descriptionUK}</div>) : null}
                <label className="mt-5" htmlFor="imgPath">Url адресс картинки</label>
                <input
                    id="imgPath"
                    name="imgPath"
                    type="text"
                    className="rounded-md px-1"
                    onChange={formic.handleChange}
                    onBlur={formic.handleBlur}
                    value={formic.values.imgPath}
                />
                {touched.imgPath && errors.imgPath ? (<div className="font-bold text-danger">{errors.imgPath}</div>) : null}
                <label className="mt-5" htmlFor="price">Цена</label>
                <input
                    id="price"
                    name="price"
                    type="number"
                    className="rounded-md px-1"
                    onChange={formic.handleChange}
                    onBlur={formic.handleBlur}
                    value={formic.values.price}
                />
                {touched.price && errors.price ? (<div className="font-bold text-danger">{errors.price}</div>) : null}
                <label className="mt-5" htmlFor="stockQuantity">Количество на складе</label>
                <input
                    id="stockQuantity"
                    name="stockQuantity"
                    type="number"
                    className="rounded-md px-1"
                    onChange={formic.handleChange}
                    onBlur={formic.handleBlur}
                    value={formic.values.stockQuantity}
                />
                {touched.stockQuantity && errors.stockQuantity ? (<div className="font-bold text-danger">{errors.stockQuantity}</div>) : null}
                <label className="mt-5" htmlFor="discount">Процент скидки</label>
                <input
                    id="discount"
                    name="discount"
                    type="number"
                    className="rounded-md px-1"
                    onChange={formic.handleChange}
                    onBlur={formic.handleBlur}
                    value={formic.values.discount}
                />
                {touched.discount && errors.discount ? (<div className="font-bold text-danger">{errors.discount}</div>) : null}
                <div className="flex items-center mt-5">
                        <label htmlFor="categoryId" className="font-bold mr-3">Категория:</label>
                        <Select
                            name="categoryId"
                            id="categoryId"
                            className="p-1"
                            value={formic.values.categoryId}
                            onChange={formic.handleChange}
                            options={categories.map(category => ({label: category.name, value: category.id} as SelectOption))}
                        />
                    </div>
                    <div className="flex items-center mt-5">
                        <label htmlFor="countryId" className="font-bold mr-3">Страна:</label>
                        <Select
                            name="countryId"
                            id="countryId"
                            className="p-1"
                            value={formic.values.countryId}
                            onChange={formic.handleChange}
                            options={countries.map(country => ({label: country.name, value: country.id} as SelectOption))}
                        />
                    </div>
                    <div className="flex items-center mt-5">
                        <label htmlFor="manufacturerId" className="font-bold mr-3">Производитель:</label>
                        <Select
                            name="manufacturerId"
                            id="manufacturerId"
                            className="p-1"
                            value={formic.values.manufacturerId}
                            onChange={formic.handleChange}
                            options={manufacturers.map(manufacturer => ({label: manufacturer.name, value: manufacturer.id} as SelectOption))}
                        />
                    </div>
                <button
                    type="submit"
                    className="bg-succes text-modal font-bold mt-10 py-2 px-4 rounded-md"
                >Добавить продукт</button>
            </form>
        </ModalWindow>
    );
}