import { useSelector } from "react-redux";
import type { RootState } from "../../store/store";
import {
  setFilterCheckBox,
  deleteFilterCheckBox,
} from "../../store/checkedFilter.slice";
import { useDispatchedActions } from "../../hooks/UseActions";

export const CheckboxElement: React.FC<{ 
  name: string;
  requestName: string;
  id: number;
}> = ({ name, requestName, id }) => {
  let checkThisBox = useDispatchedActions(setFilterCheckBox);
  let unCheckThisBox = useDispatchedActions(deleteFilterCheckBox);
  const checkedBoxesData = useSelector(
    (state: RootState) => state.filterDataCheckedReducer.filterData
  );

  let checked = false;
  
  for (let filter of checkedBoxesData) {
    if (Object.keys(filter)[0] === requestName) {
      checked = filter[requestName].includes(id);
      break;
    }
  }
  const toggleChecked = (checked:boolean) => {
    if (checked) {
      unCheckThisBox({ [requestName]: id });
    } else {
      checkThisBox({ [requestName]: id });
    }
  };

  return (
    <label>
      <input
        className="mr-1"
        type="checkbox"
        name={requestName}
        value={id}
        onChange={()=>{toggleChecked(checked)}}
        checked={checked}
      />
      {name}
    </label>
  );
};
