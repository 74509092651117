import { CloseButton } from "../buttons/CloseButton";
import type { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { ShoppingCartItem } from "./ShoppingCartItem";
import { ProductWithQuantity } from "../../interfaces/clientData";
import { useEffect, useState } from "react";
import { cartListDelete } from "../../store/shoppingCart.slice";
import { useDispatchedActions } from "../../hooks/UseActions";
import localStorageFunction from "../../servises/localStorageFunc";
import { Booking } from "./Booking";

interface FavoritesProps {
  isShow: boolean;
  toggleShow: () => void;
};

export const ShoppingCart: React.FC<FavoritesProps> = ({
  isShow,
  toggleShow,
}) => {
  const shopingCartData = useSelector(
    (state: RootState) => state.shopingCartReducer.shoppingCartData
  );
  let localStorageFunc = localStorageFunction();
  const [total, setTotal] = useState(0);
  const [isShowBooking, setIsShowBooking] = useState(false);
  const toggleShowBooking = () =>{
    setIsShowBooking(!isShowBooking)
  }
useEffect(()=>{
  console.log("effect")
},[])
  let summ = shopingCartData.reduce(
    (amount: number, product: ProductWithQuantity) => {
      return (
        +amount.toFixed(2) +
        +(
          product.quantity *
          (product.price - product.price * (product.discount / 100))
        ).toFixed(2)
      );
    },
    0
  );

  useEffect(() => {
    setTotal(+summ.toFixed(2));
  }, [summ]);

  const deleteInStore = useDispatchedActions(cartListDelete);

  const deleteCart = () => {
    deleteInStore();
    localStorageFunc.deleteCartInLocalStorage();
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={`cart-wrap fixed z-20 bg-main_02 flex justify-center cursor-auto
                          h-[100vh] w-[100vw] right-0 top-0`
                        }
    >
      <div className="cart flex flex-col justify-between bg-whitesmoke p-6 text-lg h-[90vh] max-h-full overflow-y-auto w-[75%]">
        <div className="cart-header flex items-center justify-between pb-4 h-20 border-b-4 
                      border-solid border-main_02">
          <button
            onClick={deleteCart}
            className="bg-main text-[white] self-center w-32 h-8 rounded-md text-sm"
          >
            очистити кошик
          </button>
          <div className="font-bold text-3xl pl-4 ">Кошик</div>
          <div className="font-bold text-3xl pl-4 w-1/2" >
            <CloseButton
              clickFunction={toggleShow}
              className="w-full rounded-full ml-auto mr-0"
            />
          </div>
        </div>
        <div className="cart-list ">
          {shopingCartData.map((product: ProductWithQuantity) => {
            return (
              <div key={product.id} className="cursor-text">
                <ShoppingCartItem
                  product={product}
                  closeCart={toggleShow}
                ></ShoppingCartItem>
              </div>
            );
          })}
        </div>
        <div
          className="cart-footer flex flex-col p-4
                          border-solid border-1 border-secondary rounded-md bg-secondary_light"
        >
          <div className="flex justify-between ">
            <div>разом</div>
            <div>{total} грн</div>
          </div>
          <button 
          onClick={toggleShowBooking}
          className="bg-secondary self-center w-64 h-16 rounded-md">
            оформити замовлення
          </button>
        </div>
      </div>
         {isShowBooking && <Booking isShow={isShowBooking}
          toggleShow={toggleShowBooking}/> }    
    </div>
  );
};
