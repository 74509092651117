import { useSelector } from "react-redux";
import { useGetDataQuery } from "../../servises/postServise";
import { FiltersElement } from "./filtersElements";
import type { RootState } from "../../store/store";
import { useSearchParams } from "react-router-dom";

type Item = {
  id: number;
  value: string;
};

type Attr = {
  [key: string]: Item[];
};
type NewItem = {
  id: number;
  name: string;
};

type RequestData = {
  requestName: string;
  items: Attr;
};

const renderFilters = (filters: RequestData) => {
  const { requestName, items } = filters as {
    requestName: string;
    items: Attr;
  };

  let filterKeys = Object.keys(items);
  return (
    <div>
      {filterKeys.map((key: string) => {
        let itemsValueArr: NewItem[] = [];
        items[key].map((el) => {
          itemsValueArr.push({ id: el.id, name: el.value }); // forming data accoding data Type in FiltersElement
        });
        return (
          <FiltersElement
            key={key}
            name={key}
            arr={itemsValueArr}
            requestName={requestName}
          />
        );
      })}
    </div>
  );
};

export const AuxiliaryFilter = () => {
  const mainLanguage = useSelector(
    (state: RootState) => state.languageReducer.languageValue
  );

  const [searchParams] = useSearchParams();
  const categoryId = searchParams.get("categoryIds");

  const {
    data: auxiliaryFilters = [],
    isLoading,
    // error,
  } = useGetDataQuery({
    url: `/filters/dynamic?categoryIds=${categoryId}`,
    lang: mainLanguage,
  });

  if (isLoading) return <div className="p-10 text-xs">...LOADING</div>;

  // if (error) return <h1 className="p-10">ERROR</h1>; // nuw we dont need err, it is just doesnt show

  return (
    <>
      <br></br>
      {renderFilters(auxiliaryFilters)}
    </>
  );
};
