import { FormikErrors, FormikValues, useFormik } from "formik";
import localStorageFunction from "../../servises/localStorageFunc";
import { User } from "../../servises/localStorageFunc";

interface UserFormProps {
    user: User;
    changeUser: (event: React.ChangeEvent<HTMLInputElement>) => void;
  };

export const UserForm: React.FC<UserFormProps> = ({ user, changeUser }) => {
    let localStorageFunc = localStorageFunction();
    let userData = localStorageFunc.getUserFromLocalStorage();
    
    if(!userData){userData = {
      id: '',
      name: '',
      city: '',
      phoneNumber: '',
      adress: '', 
    }}
      
    const formik = useFormik({
        initialValues: {
          id: user.id,
          name: user.name,
          city: user.city,
          phoneNumber: user.phoneNumber,
          adress: user.adress,
        },
        validate: (values) => {
          let errors: FormikErrors<FormikValues> = {};
          if (!values.name) {
            errors.name = "Поле не может бути пустим";
          } else if (values.name.length < 5) {
            errors.name = "Поле повинно мати 5 и більш символів";
          }
    
          if (!values.city) {
            errors.city = "Поле не может быть пустым";
          } else if (values.city.length < 3) {
            errors.city = "Поле должно иметь 3 и более символов";
          }
    
          if (!values.phoneNumber) {
            errors.phoneNumber = "Поле не может быть пустым";
          } else if (!/^\d{10}$/.test(values.phoneNumber)) {
            errors.phoneNumber = "Поле должно содержать ровно 10 цифр";
          }
    
          if (!values.adress) {
            errors.adress = "Поле не может быть пустым";
          } else if (values.adress.length < 10) {
            errors.adress = "Поле должно иметь 10 и более символов";
          }
          return errors;
        },
        onSubmit: (values) => {
          values.id = values.phoneNumber;
          localStorageFunc.addUserToLocalStorage(values);
        },
      });

    return(
        <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col border-b-4 
                      border-solid border-main_02 pb-6"
      >
        <div className="flex space-x-4 ">
          <div className="flex flex-col w-1/2 ">
            <label className="m-2" htmlFor="name">
              Ім'я Прізвище
            </label>
            <input
              className="border border-solid border-main rounded-md p-2 h-[30px]"
              id="name"
              name="name"
              type="text"
              onChange={(e) => {
                changeUser(e);
                formik.handleChange(e);
              }}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="font-bold text-danger">{formik.errors.name}</div>
            ) : null}
          </div>
          <div className="flex flex-col w-1/2">
            <label className="m-2" htmlFor="city">
              Місто
            </label>
            <input
              className="border border-solid border-main rounded-md p-2 h-[30px]"
              id="city"
              name="city"
              type="text"
              onChange={(e) => {
                changeUser(e);
                formik.handleChange(e);
              }}
              value={formik.values.city}
            />
            {formik.touched.city && formik.errors.city ? (
              <div className="font-bold text-danger">{formik.errors.city}</div>
            ) : null}
          </div>
        </div>
        <div className="flex space-x-4 mt-4">
          <div className="flex flex-col w-1/2">
            <label className="m-2" htmlFor="phoneNumber">
              номер телефону
            </label>
            <input
              className="border border-solid border-main rounded-md p-2 h-[30px]"
              id="phoneNumber"
              name="phoneNumber"
              type="tel"
              placeholder="(XXX)XXX-XX-XX"
              onChange={(e) => {
                changeUser(e);
                formik.handleChange(e);
              }}
              value={formik.values.phoneNumber}
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
              <div className="font-bold text-danger">
                {formik.errors.phoneNumber}
              </div>
            ) : null}
          </div>
          <div className="flex flex-col w-1/2">
            <label className="m-2" htmlFor="adress">
              адреса
            </label>
            <input
              className="border border-solid border-main rounded-md p-2 h-[30px]"
              id="adress"
              name="adress"
              type="text"
              onChange={(e) => {
                changeUser(e);
                formik.handleChange(e);
              }}
              value={formik.values.adress}
            />
            {formik.touched.adress && formik.errors.adress ? (
              <div className="font-bold text-danger">
                {formik.errors.adress}
              </div>
            ) : null}
          </div>
        </div>
        <button
          type="submit"
          className="bg-secondary_light text-main w-[128px] font-bold mt-10 py-2 px-4 rounded-md"
        >
          Зберегти
        </button>
      </form>
    )
}