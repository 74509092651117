import { useFormik } from "formik";
import { Category, Country, Manufacturer, Product } from "../../interfaces/admin.data";
import { useEffect, useState } from "react";
import axios from "axios";
import { Popup, PopupProps } from "../components/Popup";
import { Link, useParams } from "react-router-dom";
import { Select, SelectOption } from "../components/Select";
import { apiUrl } from "../../constants";


export const EditProduct = () => {
    const [product, setProduct] = useState<Product>({} as Product)
    const [categories, setCategories] = useState<Category[]>([])
    const [countries, setCountries] = useState<Country[]>([])
    const [manufacturers, setManufacturers] = useState<Manufacturer[]>([])
    const [popupIsOpen, setPopupIsOpen] = useState<boolean>(false);
    const [popup, setPopup] = useState<PopupProps>({onClose: () => setPopupIsOpen(false)});

    const {id} = useParams<string>();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [productResponse, categoriesResponse, countriesResponse, manufacturersResponse] = await Promise.all([
                    axios.get(`${apiUrl}/productsAdmin/${id}`),
                    axios.get(`${apiUrl}/categoriesAdmin`),
                    axios.get(`${apiUrl}/countriesAdmin`),
                    axios.get(`${apiUrl}/manufacturersAdmin`)
                ]);
    
                setProduct(productResponse.data);
                setCategories(categoriesResponse.data);
                setCountries(countriesResponse.data);
                setManufacturers(manufacturersResponse.data);
                formicEdit.setValues({...productResponse.data});
            } catch (error) {
                console.error('Ошибка при загрузке данных:', error);
            }
        };
    
        fetchData();        
    }, [id]);

    const updateProduct = (product: Product) => {
        (async () => {
          setPopupIsOpen(false);
          try {
            prepareProductDatabeforeUpdate();
            await axios.put(`${apiUrl}/productsAdmin/${product.id}`, product);

            setPopup({ ...popup, title: 'Выполнено', text: 'Продукт успешно изменен', className: 'bg-success_light'});
          } catch (error) {
            setPopup({ ...popup, title: 'Ошибка!',text: 'Не удалось изменить продукт', className: 'bg-danger_light'});          
          }finally{
            setPopupIsOpen(true);
          }
        })();
    }

    const prepareProductDatabeforeUpdate = () => {
        product.category = null;
        product.country = null;
        product.manufacturer = null;
        product.categoryId = Number(product.categoryId);
        product.countryId = Number(product.countryId);
        product.manufacturerId = Number(product.manufacturerId);
    } 

    const formicEdit = useFormik({
        initialValues: {...product as Product},
        onSubmit: values =>{
            updateProduct(values as Product);
        },
    })

    return (

            <div className="container max-w-5xl">
                {popupIsOpen &&
                    <Popup title={popup.title} text={popup.text} onClose={popup.onClose} className={popup.className}/>
                }
                <h3 className="text-center text-lg font-bold">Редактирование продукта</h3>
                <form
                    onSubmit={formicEdit.handleSubmit}
                    className="flex flex-col"
                >
                    <label htmlFor="name" className="font-bold mt-5">Имя на русском:</label>
                    <input
                        id="name"
                        name="name"
                        type="text"
                        className="rounded-md px-3 py-2 bg-whitesmoke"
                        onChange={formicEdit.handleChange}
                        value={formicEdit.values.name}
                    />
                    <label htmlFor="nameUK" className="font-bold mt-5">Имя на украинском:</label>
                    <input
                        id="nameUK"
                        name="nameUK"
                        type="text"
                        className="rounded-md px-3 py-2 bg-whitesmoke"
                        onChange={formicEdit.handleChange}
                        value={formicEdit.values.nameUK}
                    />
                    <label htmlFor="description" className="font-bold mt-5">Описание на русском:</label>
                    <input
                        id="description"
                        name="description"
                        type="text"
                        className="rounded-md px-3 py-2 bg-whitesmoke"
                        onChange={formicEdit.handleChange}
                        value={formicEdit.values.description}
                    />
                    <label htmlFor="descriptionUK" className="font-bold mt-5">Описание на украинском:</label>
                    <input
                        id="descriptionUK"
                        name="descriptionUK"
                        type="text"
                        className="rounded-md px-3 py-2 bg-whitesmoke"
                        onChange={formicEdit.handleChange}
                        value={formicEdit.values.descriptionUK}
                    />
                    <label htmlFor="imgPath" className="font-bold mt-5">Путь Url к картинке:</label>
                    <input
                        id="imgPath"
                        name="imgPath"
                        type="text"
                        className="rounded-md px-3 py-2 bg-whitesmoke"
                        onChange={formicEdit.handleChange}
                        value={formicEdit.values.imgPath}
                    />
                    <label htmlFor="price" className="font-bold mt-5">Цена:</label>
                    <input
                        id="price"
                        name="price"
                        type="number"
                        className="rounded-md px-3 py-2 bg-whitesmoke"
                        onChange={formicEdit.handleChange}
                        value={formicEdit.values.price}
                    />
                    <label htmlFor="stockQuantity" className="font-bold mt-5">Количество на складе:</label>
                    <input
                        id="stockQuantity"
                        name="stockQuantity"
                        type="number"
                        className="rounded-md px-3 py-2 bg-whitesmoke"
                        onChange={formicEdit.handleChange}
                        value={formicEdit.values.stockQuantity}
                    />
                    <label htmlFor="discount" className="font-bold mt-5">Общий процент скидки:</label>
                    <input
                        id="discount"
                        name="discount"
                        type="number"
                        className="rounded-md px-3 py-2 bg-whitesmoke"
                        onChange={formicEdit.handleChange}
                        value={formicEdit.values.discount}
                    />
                    <label className="font-bold mt-5 flex align-middle cursor-pointer">
                    <input
                        id="isActive"
                        name="isActive"
                        type="checkbox"
                        className="mx-1"
                        onChange={formicEdit.handleChange}
                        checked={formicEdit.values.isActive}
                    />
                        Активен ли продукт:
                    </label>
                    <div className="flex items-center mt-5">
                        <label htmlFor="categoryId" className="font-bold mr-3 min-w-32  text-right">Категория:</label>
                        <Select
                            name="categoryId"
                            id="categoryId"
                            className="p-1"
                            value={formicEdit.values.categoryId}
                            onChange={formicEdit.handleChange}
                            options={categories.map(category => ({label: category.name, value: category.id} as SelectOption))}
                        />
                    </div>
                    <div className="flex items-center mt-5">
                        <label htmlFor="countryId" className="font-bold mr-3 min-w-32 text-right">Страна:</label>
                        <Select
                            name="countryId"
                            id="countryId"
                            className="p-1"
                            value={formicEdit.values.countryId}
                            onChange={formicEdit.handleChange}
                            options={countries.map(country => ({label: country.name, value: country.id} as SelectOption))}
                        />
                    </div>
                    <div className="flex items-center mt-5">
                        <label htmlFor="manufacturerId" className="font-bold mr-3 min-w-32  text-right">Производитель:</label>
                        <Select
                            name="manufacturerId"
                            id="manufacturerId"
                            className="p-1"
                            value={formicEdit.values.manufacturerId}
                            onChange={formicEdit.handleChange}
                            options={manufacturers.map(manufacturer => ({label: manufacturer.name, value: manufacturer.id} as SelectOption))}
                        />
                    </div>
                    <div className="flex mt-10 justify-center">
                        <button
                            type="submit"
                            className="bg-succes text-modal font-bold py-2 px-4 mx-2 rounded-md"
                        >Сохранить
                        </button>
                        <Link
                            to="/admin/products"
                            className="bg-primary text-modal font-bold py-2 px-4 mx-2 rounded-md"
                        >Отменить
                        </Link>
                    </div>
                </form>            
            </div>
    );
}