import { useGetDataQuery } from "../../servises/postServise";
import { useSelector } from "react-redux";
import { Product } from "../../interfaces/clientData";
import type { RootState } from "../../store/store";
import { useLocation } from "react-router-dom";
import { BreadCrumb } from "../../components/breadCrumb/BreadCrumb";
import { setBreadCrumbData } from "../../store/breadCrumb.slice";
import { useDispatchedActions } from "../../hooks/UseActions";
import { useEffect, useState } from "react";
import { Languages } from "../../components/languagePanel/LanguagePanel";
import {
  cartListItemSet,
  cartItemQuantityChange,
} from "../../store/shoppingCart.slice";
import localStorageFunction from "../../servises/localStorageFunc";

interface ProductAttributes {
  name: string;
  value: string;
}

const isExist = (arr: Product[], id: number) => {
  for (let elem of arr) {
    if (elem.id === id) {
      return true;
    }
  }
  return false;
};

//////////////////////////////////////////////
export const ProductPage = () => {
  const mainLanguage = useSelector(
    (state: RootState) => state.languageReducer.languageValue
  );
  let location = useLocation();
  let localStorageFunc = localStorageFunction();


  const {
    // I don`t use the props for correct forcably reloading this page or if you have just link
    data: product = {},
    isLoading,
    error,
  } = useGetDataQuery({
    url: location.pathname + location.search,
    lang: mainLanguage,
  });
  const setBreadCrumb = useDispatchedActions(setBreadCrumbData);
//breadCrumps
  useEffect(() => {
    if (!isLoading && !error) {
      setBreadCrumb([
        {
          hierarchy: "category",
          name: product.category.name,
          link: `/products?categoryIds=${product.category.id}`,
        },
        {
          hierarchy: "product",
          name: product.name,
          link: location.pathname + location.search,
        },
      ]);
    }
  }, [
    product,
    isLoading,
    error,
    location.pathname,
    location.search,
    setBreadCrumb,
  ]);
///////////////
  const [count, setCount] = useState(1);

  const handleMinus = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const handlePlus = () => {
    setCount(count + 1);
  };
// for shoppingCart
  const shopingCartData = useSelector(
    (state: RootState) => state.shopingCartReducer.shoppingCartData
  );
  const addCartListItemToStore = useDispatchedActions(cartListItemSet);
  const changeQuantityInStore = useDispatchedActions(cartItemQuantityChange);

  let isExistInCartList: boolean = isExist(shopingCartData, product.id);

  const addToCart = () => {
    if (isExistInCartList) {
      changeQuantityInStore({[product.id]: count });
      localStorageFunc.changeCartItemQuantityInLocalStorage(product.id.toString(), count)
    } else {
      addCartListItemToStore({ ...product, quantity: count });
      localStorageFunc.addCartItemToLocalStorage(product.id.toString(), count);
    }
  };

  if (isLoading) return <div className="p-10 text-xs">...LOADING</div>;

  if (error) return <h1 className="p-10">ERROR</h1>;

  return (
    <div className="productPage">
      <BreadCrumb />
      <div className="product-wrap pt-8 h-full">
        <h1
          className="text-[20px] text-center my-4
                      md:text-[28px] 
                      lg:text-[36px] lg:leading-10 "
        >
          {product.name}
        </h1>
        <div
          className="flex flex-col 
                      md:flex-row"
        >
          <div
            className="productImg relative h-full flex items-center justify-center
                       md:w-1/2 "
          >
            <div
              className="img-wrap relative flex justify-center items-center contentItem-img 
                          h-[200px] w-[300px]
                          md:mt-16 md:h-[500px]
                          lg:mt-0 lg:h-[500px] "
            >
              {!!product.discount && (
                <div
                  className="action left-[0px] top-0 absolute w-24 h-6 p-[2px] rounded-[8px] 
                          text-center bg-[red] text-[white] text-[18px] 
                          transform skew-y-[-12deg] skew-x-[12deg]
                          lg:top-6"
                >
                  {product.discount}%
                </div>
              )}
              <img
                className="object-contain h-full w-full"
                src={product.imgPath}
                alt="foto"
              />
            </div>
          </div>
          <div
            className="infoWrap text-center p-4 mt-6 
                       md:w-1/2 md:text-left md:mt-10"
          >
            {product.discount ? (
              <div className="">
                <div
                  className="text-[20px] line-through h-8
                            lg:text-[30px] "
                >
                  {product.price} грн
                </div>
                <div
                  className="text-[26px] text-red h-10
                            lg:text-[48px]"
                >
                  {product.price - product.price * (product.discount / 100)} грн
                </div>
              </div>
            ) : (
              <div
                className="text-[26px] h-10 
                          lg:text-[48px]"
              >
                {product.price} грн
              </div>
            )}
            <div
              className="count flex m-auto text-[30px] items-center justify-center
                        md:justify-start 
                        lg:text-[48px]"
            >
              <button
                className="h-10 w-10 border-main_02 rounded-[100%] pb-2 border-1 
                          lg:w-16 lg:h-16"
                onClick={handleMinus}
              >
                -
              </button>
              <input
                type="number"
                min={1}
                value={count}
                onChange={(e) => setCount(parseInt(e.target.value))}
                className="text-[24px] border-1 border-main_02 w-[80px] m-4 text-center pl-3
                          lg:text-[36px] lg:w-[130px] lg:h-16 "
              />
              <button
                className="h-10 w-10 border-1 border-main_02 rounded-[100%]
                          lg:h-16 lg:w-16 "
                onClick={handlePlus}
              >
                +
              </button>
            </div>
            <button
            onClick={addToCart}
              className="bg-secondary w-40 h-10 rounded-full my-4 text-[20px] 
                          lg:text-[24px]"
            >
              {Languages.Ukrainian === mainLanguage ? "купити" : "купить"}
            </button>

            <div
              className="characteristics mt-8 text-left text-[16px] 
                        lg:text-[18px]"
            >
              <div
                className="mb-4 text-[18px] font-bold
                          lg:text-[20px] "
              >
                {Languages.Ukrainian === mainLanguage
                  ? "Характеристики"
                  : "Характеристики"}
              </div>
              <div className="mb-4">опис: {product.description}</div>
              <div className="mb-4">Тип пристрою: {product.category.name}</div>
              <div className="mb-4">
                Країна виробник: {product.country.name}
              </div>
              <div className="mb-4">
                Виробник товару: {product.manufacturer.name}
              </div>
              {product.productAttributes.map(
                (el: ProductAttributes, idx: number) => {
                  return (
                    <div className="mb-4" key={idx}>
                      {el.name}: {el.value}{" "}
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
