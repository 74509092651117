import { FiltersElement } from "./filtersElements";
import type { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { useEffect } from "react";

export const MainFilters = () => {
  const filters = useSelector(
    (state: RootState) => state.filtersSliceReducer.mainFilters
  );

  return (
    <div>
      {filters.map((filter: Object) => {
        let { name, requestName, items } = filter as {
          name: string;
          requestName: string;
          items: any[];
        };
        if (requestName !== "categoryIds") {
          //because server return all filters but we don't need category
          return (
            <FiltersElement
              key={requestName}
              name={name}
              arr={items}
              requestName={requestName}
            />
          );
        }
      })}
    </div>
  );
};
