import { useGetDataQuery } from "../../servises/postServise";
import { useSelector } from "react-redux";
import type { RootState } from "../../store/store";
import { Product } from "../../interfaces/clientData";
import { ContentItem } from "./ContentItem";
import { useLocation } from "react-router-dom";
import { setBreadCrumbData } from "../../store/breadCrumb.slice";
import { useDispatchedActions } from "../../hooks/UseActions";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Languages } from "../../components/languagePanel/LanguagePanel";
import { RequiredQuatnity } from "../../components/requiredQuatnity/RequiredQuatnity";
import { SorterPanel } from "../../components/filter/SorterPanel";
import { Filter } from "../../components/filter/Filter";

export const InvitedContent = () => {
  const mainLanguage = useSelector(
    (state: RootState) => state.languageReducer.languageValue
  );
  let location = useLocation();
  const setBreadCrumb = useDispatchedActions(setBreadCrumbData);
  const [searchParams] = useSearchParams();
  const categoriesId = searchParams.get("categoryIds");
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const toggleFilter = (value: boolean) => {
    setIsOpenFilter(!value);
  };
  const {
    data: products = [],
    isLoading,
    error,
  } = useGetDataQuery({
    url: location.pathname + location.search,
    lang: mainLanguage,
  });
  let url = ''
  if (categoriesId){
    url = `/categories/${categoriesId}`
  } else url = `/categories`;

  const {
    data: categoryName = [],
    isLoading: isCategoryNameLoading,
    error: categoryNameError,
  } = useGetDataQuery({
    url: url,
    lang: mainLanguage,
  });

  useEffect(() => {
    if (!isCategoryNameLoading && !categoryNameError && categoriesId) {
      setBreadCrumb([
        {
          hierarchy: "category",
          name: categoryName.name,
          link: location.pathname + location.search,
        },
      ]);
    } 
    else setBreadCrumb([]);
  }, [categoryName, categoryNameError, isCategoryNameLoading, categoriesId]);

  if (isLoading) return <div className="p-10 text-xs">...LOADING</div>;

  if (error) return <h1 className="p-10">ERROR</h1>;
  return (
    <div className="invitedContent flex flex-wrap h-full justify-between lg:justify-normal">
      <div
        className="flex justify-between items-center w-full flex-col 
                      sm:flex-row"
      >
        <div
          className="flex items-center justify-start w-full
                        sm:justify-between sm:w-auto"
        >
          <button
            className="bg-secondary h-8 w-16 mr-5 lg:hidden"
            onClick={() => {
              toggleFilter(isOpenFilter);
            }}
          >
            {Languages.Ukrainian === mainLanguage ? "Фільтр" : "Фильтр"}
          </button>
          <RequiredQuatnity
            locationPathName={location.pathname}
            locationSearch={location.search}
          />
        </div>
        <SorterPanel />
      </div>
      <div className="lg:flex lg:flex-row w-full">
        <div className="lg:w-[30%] p-4">
          <Filter isOpenFilter={isOpenFilter} setIsOpenFilter={setIsOpenFilter} />
        </div>
        
        <div className="products-wrap flex flex-wrap h-auto justify-between lg:justify-normal w-full lg:w-[70%]">
          {products.length ? (
            products.map((product: Product) => (
              <div key={product.id}className="sm:w-[43%] lg:mr-4 lg:ml-4 lg:w-[28%]
              2xl:w-[23%] 2xl:m-2">
                <ContentItem  {...product} />
              </div>
              
            ))
          ) : (
            <div className="text-center font-bold"> данных пока нет</div>
          )}
        </div> 
       </div>
    </div>
  );
};
