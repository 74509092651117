import { NavLink } from "react-router-dom";
import { BreadCrumb } from "../../components/breadCrumb/BreadCrumb";
import { InvitedContent } from "../../components/invitedContent/InvitedContent";
import { useGetDataQuery } from "../../servises/postServise";
import { useSelector } from "react-redux";
import type { RootState } from "../../store/store";
import { useSearchParams } from "react-router-dom";
import { useDispatchedActions } from "../../hooks/UseActions";
import {
  setFilterName,
  addFilterName,
  setFilterDefaultCheckBox,
} from "../../store/checkedFilter.slice";
import { useEffect } from "react";
import { setMainFilters } from "../../store/mainFilters.slice";

export const MainPage = () => {
  const [searchParams] = useSearchParams();
  const setCheckedFilters = useDispatchedActions(setFilterName);
  const addCheckedFilters = useDispatchedActions(addFilterName);
  const setDefaultCheckBox = useDispatchedActions(setFilterDefaultCheckBox);
  const setFilters = useDispatchedActions(setMainFilters);

  const mainLanguage = useSelector(
    (state: RootState) => state.languageReducer.languageValue
  );

  const {
    data: filters = [],
    isLoading,
    error,
  } = useGetDataQuery({
    url: "/filters/static",
    lang: mainLanguage,
  });

  useEffect(() => {
    setFilters(filters)
  }, [filters]);

  let categoryId: string | null = "";

  categoryId = searchParams.get("categoryIds");

  useEffect(() => {
    let newArr = [];
    newArr = filters.map((filter: any) => {
      let { requestName } = filter as { requestName: string };
      return { [requestName]: [] };
    });
    setCheckedFilters(newArr); //add array for filters checkboxes state
    addCheckedFilters({ productAttrIds: [] }); //add object with array for auxiliaryFilter checkboxes state
    if (categoryId) {
      setDefaultCheckBox({ categoryIds: +categoryId });
    }
  },
  [filters, categoryId] //if delete this line and add filter 'category' there are always will be chek only filter category accoding category in header
  );
  

  if (error) return <h1 className="p-10">ERROR</h1>;
  if (isLoading) return <div className="p-10 text-xs">...LOADING</div>;

  return (
    <main className="mainPage p-3">
      <NavLink
        to="/admin"
        className="absolute top-10 right-100 px-4 py-2 bg-succes 
                   rounded-md font-bold text-modal"
      >
        Admin panel
      </NavLink>
      <BreadCrumb />
      <InvitedContent></InvitedContent>
    </main>
  );
};
