import { CloseButton } from "../buttons/CloseButton";
import type { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { ContentItem } from "../invitedContent/ContentItem";
import { Product } from "../../interfaces/clientData";
import { useDispatchedActions } from "../../hooks/UseActions";
import {deleteFavorites} from "../../store/favorites.slice";
import localStorageFunction from "../../servises/localStorageFunc";

interface FavoritesProps {
  isShow: boolean;
  toggleShow: () => void;
};

export const Favorites: React.FC<FavoritesProps> = ({ isShow, toggleShow }) => {
  const favorites = useSelector(
    (state: RootState) => state.favoritesReducer.favoritesData
  );

  const deleteWishesInStore = useDispatchedActions(deleteFavorites);
  let localStorageFunc = localStorageFunction();

  const deleteWishes = () => {
    deleteWishesInStore();
    localStorageFunc.deleteFavoritesInLocalStorage();
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={`favoritesWrap fixed z-20 bg-main_02 flex justify-center
                  h-[100vh] w-[100vw] right-0 top-0
                  `}
    >
      <div
        className="favorites bg-whitesmoke p-6 h-[95vh] max-h-full overflow-y-auto w-[100%]
                      sm:w-[75%]"
      >
        <div
          className="favorites-head flex justify-between items-center pb-4 h-20 border-b-4 
                        border-solid border-main_02"
        >
          <button
            onClick={deleteWishes}
            className="bg-main text-[white] self-center w-32 h-8 rounded-md text-sm">
            очистити
          </button>
          <div className="font-bold text-3xl pl-4 ">Список бажань</div>
          <div className="font-bold text-3xl pl-4 w-1/3">
            <CloseButton
              clickFunction={toggleShow}
              className="w-full rounded-full ml-auto mr-0"
            />
          </div>
        </div>
        <div
          className="favorites-list flex flex-wrap  justify-between w-full
                    lg:justify-normal  "
        >
          {favorites.map((product: Product) => (
            <div
              key={product.id}
              className="sm:w-[43%] lg:mr-4 lg:ml-4 lg:w-[28%]
                              2xl:w-[23%] 2xl:m-2"
              onClick={toggleShow}
            >
              <ContentItem key={product.id} {...product} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
