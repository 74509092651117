import { useSearchParams } from "react-router-dom";
import { CloseButton } from "../buttons/CloseButton";
import { useSelector } from "react-redux";
import type { RootState } from "../../store/store";
import { Languages } from "../languagePanel/LanguagePanel";
import { FormikErrors, FormikValues, useFormik } from "formik";
import { AuxiliaryFilter } from "./AuxiliaryFiltr";
import  {MainFilters} from "./MainFilters";
import React from "react";

interface FormValues {
  minPrice?: string;
  maxPrice?: string;
  [key: string]: number[] | string | undefined;
}

type FilterProps = {
  isOpenFilter: boolean;
  setIsOpenFilter: Function;
};

export const Filter: React.FC<FilterProps> = ({
  isOpenFilter,
  setIsOpenFilter,
}) => {

  const mainLanguage = useSelector(
    (state: RootState) => state.languageReducer.languageValue
  );
  const searchPanelValue = useSelector(
    (state: RootState) => state.searchPanelValueReducer.searchPanelValue
  );
  const dataForRequest = useSelector(
    (state: RootState) => state.filterDataCheckedReducer.filterData
  );

  const [searchParams, setSearchParams] = useSearchParams();

  let initialValues: FormValues = {
    minPrice: "",
    maxPrice: "",
  };

  let defaultSearchParams: any = {};

  if (searchPanelValue) {
    defaultSearchParams = { PartOfName: searchPanelValue };
  }

  let categoryId = searchParams.get("categoryIds");

  const formik = useFormik<FormValues>({
    initialValues,
    validate: (values) => {
      let errors: FormikErrors<FormikValues> = {};
      if (
        values.maxPrice &&
        values.minPrice &&
        values.maxPrice < values.minPrice
      ) {
        errors.maxPrice = "максимальна ціна не може бути менша за мінімальну";
      }
      return errors;
    },
    onSubmit: (values) => {
      let newValues: Object = {};

      dataForRequest.forEach((el) => {
        let key = Object.keys(el)[0];
        if (el[key].length > 0) {
          values[key] = el[key] 
        }
      });
      let maxPrice: Object;
      let minPrice: Object;
      if (values.maxPrice === "" && values.minPrice === "") {
        ({ minPrice, maxPrice, ...newValues } = values);
      } else if (values.maxPrice === "") {
        ({ maxPrice, ...newValues } = values);
      } else if (values.minPrice === "") {
        ({ minPrice, ...newValues } = values);
      } else {
        newValues = { ...values };
      }
      setSearchParams({ ...defaultSearchParams, ...newValues });
    },
  });

  const { touched, errors } = formik;

  return (
    <form
      className={`filter bg-secondary pt-0 text-36px h-screen overflow-y-auto z-50 w-1/2
                    text-sm absolute top-0 border-1 border-main_02 p-5
                    left-[calc((-100vw+theme('width.full')+theme('container.padding.DEFAULT'))/2)]
                    transition-transform transform 
                    ${isOpenFilter ? "translate-x-0" : "-translate-x-full"} 
                    lg:static lg:translate-x-0 lg:w-[100%] lg:h-[100%] lg:max-h-[800px] lg:bg-transparent 
                    lg:text-base 
                    2xl:w-[100%]`}
      onSubmit={formik.handleSubmit}
    >
      <CloseButton
        clickFunction={setIsOpenFilter}
        className="w-full rounded-full ml-auto mr-0
                    lg:hidden "
      />
      <div className="my-8">
        <div className="flex justify-around">
          <label htmlFor="minPrice">
            {Languages.Ukrainian === mainLanguage ? "від" : "от"}
          </label>
          <input
            className="mx-2 w-16 border-1 border-main_02 lg:w-20"
            id="minPrice"
            name="minPrice"
            type="number"
            min={0}
            onChange={formik.handleChange}
            value={formik.values.minPrice}
          />
          <label htmlFor="maxPrice">до</label>
          <input
            className="ml-2 w-16 border-1 border-main_02 lg:w-20"
            id="maxPrice"
            name="maxPrice"
            type="number"
            min={0}
            onChange={formik.handleChange}
            value={formik.values.maxPrice}
          />
        </div>
        {touched.maxPrice && errors.maxPrice ? (
          <div className="font-bold text-danger text-sm">{errors.maxPrice}</div>
        ) : null}
      </div>
      <MainFilters />
      {categoryId && <AuxiliaryFilter/>}
      <button
        className=" bg-main w-28 h-10 text-[white] mx-auto my-4 block"
        type="submit"
      >
        {Languages.Ukrainian === mainLanguage ? "підтвердити" : "подтвердить"}
      </button>
    </form>
  );
};
