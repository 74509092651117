import { configureStore } from "@reduxjs/toolkit";
import languageReducer from "./language.slice";
import breadCrumbReducer from "./breadCrumb.slice"
import searchPanelValueReducer from "./searchPanelValue.slice"; 
import filterDataCheckedReducer from "./checkedFilter.slice";
import filtersSliceReducer from "./mainFilters.slice";
import favoritesReducer from "./favorites.slice";
import shopingCartReducer from "./shoppingCart.slice"
import { postAPI} from "../servises/postServise";

export const store = configureStore({
    reducer: {
        [postAPI.reducerPath]: postAPI.reducer,
        languageReducer, 
        breadCrumbReducer,
        searchPanelValueReducer,
        filterDataCheckedReducer,
        filtersSliceReducer,
        favoritesReducer,
        shopingCartReducer
    },
    middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(postAPI.middleware),
	devTools: process.env.NODE_ENV !== 'production'
    
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;