import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Product } from "../interfaces/clientData";

export interface FavoritesState {
  favoritesData: Product[];
}

const initialState: FavoritesState = {
  favoritesData: [],
};

export const favorites = createSlice({
  name: "favorites",
  initialState,
  reducers: {
    addFavorite: (state, action: PayloadAction<Product>) => {
      state.favoritesData.push(action.payload);
    },
    addAllFavorites: (state, action: PayloadAction<Product[]>) => {
      state.favoritesData = action.payload;
    },
    deleteFavorites: (state) => {
      state.favoritesData = [];
    },
    deleteFavoritesItem: (state, action) => {
      state.favoritesData = state.favoritesData.filter((item) => {
        return item.id !== action.payload;
      });
    },
  },
});

const { actions, reducer } = favorites;

export default reducer;
export { actions };
export const { addFavorite, addAllFavorites, deleteFavorites, deleteFavoritesItem } = actions;
