import { useState } from "react";
import type { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { Favorites } from "../favorites/Favorites";
import { ShoppingCart } from "../shoppingCart/ShoppingCart";

export const IconsPanel = () => {
  
  const shopingCartData = useSelector(
    (state: RootState) => state.shopingCartReducer.shoppingCartData
  );
  const favorites = useSelector(
      (state: RootState) => state.favoritesReducer.favoritesData
    );
   

  const [isShowFavorites, setIsShowFavorites] = useState(false);
  const [isShowCart, setIsShowCart] = useState(false);
  // const addToFavorites = useDispatchedActions(addAllFavorites);
  // addToFavorites(favorites)

  // if (isLoading) return <div className="p-10 text-xs">...LOADING</div>;

  // if (error) return <h1 className="p-10">ERROR</h1>;
 
 
  const toggleShowFavorite = () =>{
    setIsShowFavorites(!isShowFavorites)
  }

  const toggleShowCart = () =>{
    setIsShowCart(!isShowCart)
  }

  return (
    <div
      className="iconsPanel-wrap flex justify-between min-w-[84px]
                    md:min-w-[120px] "
    >
      <div 
        onClick={()=>{toggleShowFavorite()}}
        className={`iconsPanel-icon px-[1px] md:px-1 relative`}>
          {isShowFavorites && <Favorites 
                              isShow={isShowFavorites}
                              toggleShow={toggleShowFavorite}
          />}
        <div className="absolute bottom-6 right-[-3px] font-bold">{favorites.length}</div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="-2 0 28 22"
          strokeWidth={1.5}
          className={`w-6 h-6 sm:w-8 sm:h-8 bg-main rounded-full 
                  fill-secondary stroke-secondary
                  `}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
          />
        </svg>
      </div>
      <div 
        onClick={()=>{toggleShowCart()}}
        className="iconsPanel-icon px-[1px] md:px-1 relative">
          {isShowCart && 
          <ShoppingCart 
          isShow={isShowCart}
          toggleShow={toggleShowCart}
          />}
        <div className="absolute bottom-6 right-[-3px] font-bold">{shopingCartData.length}</div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="-2 0 28 22"
          strokeWidth={1.5}
          className={`w-6 h-6 sm:w-8 sm:h-8 bg-main rounded-full stroke-secondary`}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
          />
        </svg>
      </div>
      <div className="iconsPanel-icon px-[1px] md:px-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          className={`w-6 h-6 sm:w-8 sm:h-8 bg-main rounded-full stroke-secondary`}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
          />
        </svg>
      </div>
    </div>
  );
};
