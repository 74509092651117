import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface BreadCrumbItem {
  hierarchy: string
  name: string;
  link: string;
}

export interface BreadCrumbState {
  dataForBreadCrumb: BreadCrumbItem[];
}

const initialState: BreadCrumbState = {
  dataForBreadCrumb: [{hierarchy:'HOME',name:'ElectroGoods', link:'/'}],
};

export const breadCrumbSlice = createSlice({
  name: "breadCrumb",
  initialState,
  reducers: {
    setBreadCrumbData: (state, action: PayloadAction<BreadCrumbItem[]>) => {
      state.dataForBreadCrumb =  [{hierarchy:'HOME',name:'ElectroGoods', link:'/'}, ...action.payload]
    },
  },
});

const { actions, reducer } = breadCrumbSlice;

export default reducer;
export { actions };
export const { setBreadCrumbData } = actions;