import{
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
  Outlet
} from 'react-router-dom';

import { MainPage } from './pages/main/MainPage';
import { Footer } from './components/footer/Footer';
import { NotFound } from './pages/notFound/NotFound';
import { Header } from './components/header/Header';
import { PersonalArea } from './pages/personalArea/PersonalArea';
import { AdminPage } from './AdminPanel/AdminPage';
import { ProductPage } from './pages/productPage/ProductPage';
import { HomePage } from './pages/home/Home';

const App = () => {

  const Root = () => {
    return (
      <div className="container relative h-[100vh] py-3 ">
        <div className='containerWrap flex flex-col justify-between h-full'>
          <Header />     
          <div className='min-h-auto grow'>
            <Outlet />
          </div>
          <Footer />   
        </div>
      </div>
      );
  };
  
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path='/' element={<Root />}>
        <Route index element={<HomePage/>} />
        <Route path='/admin/*' element={<AdminPage/>} />
        <Route path='/personalarea/:userId' loader={loader} element={<PersonalArea />} errorElement={<NotFound/>} />
        <Route path='*' element={<NotFound />} />
        <Route path='/products/:productId' element={<ProductPage/>} />
        <Route path='/products' element={<MainPage/>} />

      </Route>
    )
  );
  
  return (
     <RouterProvider router={router} />
  ) 
};

function loader(params:any){
  //There will be a database query
  return params;
}

export default App;