interface CloseButtonProps {
  clickFunction: Function
  className: string
}

export const CloseButton: React.FC<CloseButtonProps> = ({clickFunction, className }) => {
  return (
    <button type='button' className={className} onClick={()=>clickFunction()} >
      <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="rgba(44, 68, 86, 0.9)"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="#ff8d35"
      className='block w-10 h-10 ml-auto mr-3 mt-3'
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
      />
    </svg>
    </button>
  );
};
