import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ProductWithQuantity } from "../interfaces/clientData";

export interface ShoppingCartState {
  shoppingCartData: ProductWithQuantity[];
}
interface QuantityWithID {
  [id: number]: number;
}

const initialState: ShoppingCartState = {
  shoppingCartData: [],
};

const shopingCart = createSlice({
  name: "shoppingCart",
  initialState,
  reducers: {
    cartListItemSet: (state, action: PayloadAction<ProductWithQuantity>) => {
      state.shoppingCartData.push(action.payload);
    },
    cartSet: (state, action: PayloadAction<ProductWithQuantity[]>) => {
      state.shoppingCartData = action.payload;
    },
    cartListDelete: (state) => {
      state.shoppingCartData = [];
    },
    cartListDeleteItem: (state, action: PayloadAction<number>) => {
      state.shoppingCartData = state.shoppingCartData.filter((item) => {
        return item.id !== action.payload;
      });
    },
    cartItemQuantityChange: (state, action: PayloadAction<QuantityWithID>) => {
        let payloadKey = +(Object.keys(action.payload)[0])
      let element = state.shoppingCartData.find(
        (el) => el.id === payloadKey
      );
      if(element){element.quantity = action.payload[payloadKey];
      }
    },
  },
});

const { actions, reducer } = shopingCart;

export default reducer;
export const {
  cartSet,
  cartListItemSet,
  cartListDelete,
  cartListDeleteItem,
  cartItemQuantityChange,
} = actions;
