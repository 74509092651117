import { useSearchParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import type { RootState } from "../../store/store";
import { useDispatchedActions } from "../../hooks/UseActions";
import { setSearchPanelValue } from "../../store/searchPanelValue.slice";
import { useState } from "react";

export const SearchPanel = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const setDataSearchPanel = useDispatchedActions(setSearchPanelValue);
  const panelSearchValue = useSelector(
    (state: RootState) => state.searchPanelValueReducer.searchPanelValue
  );
  const [isShowinfoSearchPanel, setIsShowinfoSearchPanel] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    const searchValue = form.search.value;
    if (searchValue) {
      navigate("/products");
      setDataSearchPanel(searchValue);
      setSearchParams({ PartOfName: searchValue });
      setIsShowinfoSearchPanel(true);
      form.search.value = ''
    }
  };

  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const value = e.target.value;
  //   if (value === "") {
  //     setIsShowinfoSearchPanel(false);
  //     setDataSearchPanel("");
  //   }
  // };

  return (
    <form
      onSubmit={(e) => {
        handleSubmit(e);
      }}
      className="searchForm-wrap block h-8 md:h-8 relative
                 lg:min-w-[300px] 
                 xl:min-w-[500px]"
    >
      <div className="searchForm-element-wrapper flex relative">
        <input
          type="search"
          name="search"
          className="searchForm-input w-full h-8 md:h-8 pr-8 pl-5 
                      border-1 border-solid border-main_02 rounded-full text-base"
          id="searchForm-input text-sm"
          placeholder="Пошук..."
          autoComplete="off"
          // onChange={handleChange}
        />
        <button
          type="submit"
          className="searchForm-icon-wrap absolute text-secondary 
                     right-[8px] top-[3px] bottom-0 rounded-r-10
           "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
            />
          </svg>
        </button>
      </div>
      {isShowinfoSearchPanel && (
        <div className="infoSearchPanel absolute flex items-center justify-between px-4 py-1
                        top-[40px] border-4 border-solid border-[white] rounded-[30px] w-full
                       bg-secondary_light h-10 text-[16px] 
                        lg:w-[calc((100%)+50%)] lg:left-[-25%] lg:h-16 lg:top-[20px]  
                        lg:border-8 lg:mt-5 lg:px-6 lg:z-20">
          Результати пошуку: "{panelSearchValue}"
          <button className="closeInfoSearchPanel"
                  onClick={()=>{
                    setIsShowinfoSearchPanel(false);
                    setDataSearchPanel("");
                    navigate("/products");
                  }}>x
          </button>
        </div>
      )}
    </form>
  );
};