import { ProductAttribute } from '../../../interfaces/admin.data';
import { EditButton } from '../../components/EditButton';
import { RemoveButton } from '../../components/RemoveButton';

interface AttributeItemProps{
    attribute: ProductAttribute;
    removeAttribute: Function;
}

export const AttributeItem : React.FC<AttributeItemProps> = ({attribute, removeAttribute}) => {

    return(
        <tr className="py-2 px-4
            hover:shadow-[0_0_10px_rgba(0,0,0,0.2)]
            transition-shadow duration-300 rounded-lg"
            >
            <td className='px-2 text-center'>{attribute.attributeId}</td>
            <td className='px-2 text-center'>{attribute.productId}</td>
            <td className='px-2'>{attribute.attributeName}</td>
            <td className='px-2 text-center'>{attribute.attributeValue}</td>
            <td className='px-2'>{attribute.attributeNameUK}</td>
            <td className='px-2 text-center'>{attribute.attributeValueUK}</td>
            <td className='flex justify-end py-2'>
                <EditButton onClick={()=>{}}/>
                <RemoveButton onClick={()=> removeAttribute(attribute.attributeId)} />
            </td>
        </tr>
    );
}