import { HTMLAttributeReferrerPolicy } from "react";
import { Link } from "react-router-dom";

interface ListLinkProps{
    to: string;
}

export const ListLink: React.FC<ListLinkProps> = ({to}) => {
    return(
        <Link
            to={to}
            className='flex items-center justify-center w-10 h-10 mr-2 bg-success_light rounded-lg shadow-lg hover:scale-110 transition-transform duration-300'
        >
            <span>
                &#128221;
            </span>
        </Link>
    );
}